import { useActionBarContext } from "@/components/contexts/action-bar-context";
import {
  DocumentationCard,
  DocumentationCardButtonGroup,
  DocumentationCardContent,
  DocumentationCardHeader,
  DocumentationCardItem,
  DocumentationCardItemButtonGroup,
  DocumentationCardItemHeader,
  DocumentationCardItemTitle,
  DocumentationCardTitle,
} from "@/components/documentation-card";
import { H5 } from "@/components/headings";
import LayoutRightDrawer, {
  LayoutSidebar,
  LayoutSidebarContent,
  LayoutSidebarHeader,
} from "@/components/layout-right-drawer";
import {
  DescriptionCard,
  EditorCard,
  EditorItemDescription,
  HideEmptyListWhenNonEditor,
  PreviewHeading,
  PreviewToolbarContainer,
} from "@/components/module-api-editor";
import { EditorPreviewErrorBoundary } from "@/components/module-api-editor/editor-preview-error-boundary";
import { LinkCard } from "@/components/module-api-editor/editor-preview-operation";
import {
  ActiveElement,
  EditorInputProps,
  WorkspaceRole,
} from "@/components/module-api-editor/types";
import { useHandleCopy } from "@/components/module-api-editor/use-handle-copy";
import {
  AddButton,
  AddLabelButton,
  BtnGroup,
  DeleteButton,
  EditButton,
} from "@/components/module-visual-editor/shared-components";
import { SchemaQuickEditor } from "@/components/schema-quick-editor";
import {
  SecondaryCard,
  SecondaryCardContent,
  SecondaryCardHeader,
} from "@/components/secondary-card";
import { TypeBadge } from "@/components/special-badges";
import {
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  BreadcrumbList,
  BreadcrumbPage,
  BreadcrumbSeparator,
} from "@/components/ui/breadcrumb";
import { ComponentSchemaWithInfo, useComponents } from "@/hooks/use-components";
import { DtoSchemaWithInfo, useSchemaDtos } from "@/hooks/use-dtos";
import { getSchemaTypeName } from "@/lib/oas-tools/oas-schema-utils";
import { typeColorMap } from "@/lib/oas-tools/style-helpers";
import { OASComponentsObject } from "@/lib/types";
import { canEdit, toastError, toastSuccess } from "@/lib/utils";
import { Link } from "@tanstack/react-router";
import { Dispatch, ReactNode, SetStateAction, useMemo, useState } from "react";

function LayoutPlaceholderSidebar({
  value,
  onChange,
  activeElement,
  componentsObject,
  workspaceRole,
}: EditorInputProps & {
  activeElement: Extract<ActiveElement, { type: "model" }>;
  componentsObject: OASComponentsObject;
  workspaceRole: WorkspaceRole;
}) {
  const [, actionBarDispatch] = useActionBarContext();
  const { handleCopyComponent } = useHandleCopy({ onChange, value });

  const handleCopyActive = async () => {
    if (!activeElement) return;
    await handleCopyComponent({ activeElement, componentsObject });
  };

  const handleOpenCrudWizard = () => {
    actionBarDispatch({
      type: "SET_PAGE",
      payload: {
        name: "crud-wizard",
        context: {
          schemaName: activeElement.modelName,
        },
      },
    });
  };

  const isEditor = canEdit(workspaceRole);
  return (
    <LayoutSidebar className="bg-background py-0">
      <LayoutSidebarContent>
        <div className="px-4 grid grid-cols-1 gap-2 gap-y-2 text-left">
          <H5 className="text-muted-foreground pb-2">Quick actions</H5>
          {isEditor && (
            <LinkCard
              title="New schema"
              icon={<span>➕</span>}
              subtitle="Create reusable schema"
              onClick={() =>
                actionBarDispatch({
                  type: "SET_PAGE",
                  payload: { name: "add-component-schema" },
                })
              }
            />
          )}
          <LinkCard
            title="Copy model"
            icon={<span>📝</span>}
            subtitle="Schema to clipboard"
            onClick={handleCopyActive}
          />
          {isEditor && (
            <LinkCard
              title="CRUD Wizard"
              subtitle="Quickly create CRUD operations"
              icon={<span>🧙🏻</span>}
              onClick={handleOpenCrudWizard}
            />
          )}
        </div>
      </LayoutSidebarContent>
    </LayoutSidebar>
  );
}

export function EditorPreviewModel({
  activeElement,
  onChange,
  value,
  isAuthed,
  workspaceSlug,
  organizationSlug,
  workspaceRole,
  componentsObject,
  extraToolbarItems,
}: {
  activeElement: NonNullable<ActiveElement>;
  isAuthed: boolean;
  workspaceSlug: string | undefined;
  organizationSlug: string | undefined;
  workspaceRole: WorkspaceRole;
  componentsObject: OASComponentsObject;
  extraToolbarItems: ReactNode;
} & EditorInputProps) {
  const [, actionBarDispatch] = useActionBarContext();
  const { getComponentSchemaOrError, removeComponentSchema } = useComponents({
    value,
    onChange,
  });

  const [activeDto, setActiveDto] = useState<DtoSchemaWithInfo | undefined>(
    undefined
  );

  const handleEditClick = (
    componentSchemaWithInfo: ComponentSchemaWithInfo
  ) => {
    actionBarDispatch({
      type: "SET_PAGE",
      payload: {
        name: "edit-component-schema",
        context: {
          schemaName: componentSchemaWithInfo.name,
          componentSchemaObject: componentSchemaWithInfo.schema,
        },
      },
    });
  };

  const handleRemoveClick = async (
    componentSchemaWithInfo: ComponentSchemaWithInfo
  ) => {
    try {
      await removeComponentSchema(componentSchemaWithInfo.name);
      toastSuccess("Component deleted");
    } catch (err) {
      toastError(err);
    }
  };

  const handleAdd = () => {
    actionBarDispatch({
      type: "SET_PAGE",
      payload: {
        name: "dto-add",
        context: {
          baseSchemaName: componetSchema.name,
        },
      },
    });
  };

  if (activeElement.type !== "model")
    throw new Error("Active element needs to be model");

  const componetSchema = useMemo(() => {
    return getComponentSchemaOrError(activeElement.modelName);
  }, [activeElement.modelName, getComponentSchemaOrError]);

  const dtoSchemaList = componetSchema.dtoSchemas || [];

  const typeName = getSchemaTypeName(componetSchema.schema);
  const color = typeColorMap[typeName];
  if (!color) throw new Error("Can't find color for type");

  const isEditor = canEdit(workspaceRole);
  return (
    <div>
      <PreviewToolbarContainer
        workspaceRole={workspaceRole}
        isAuthed={isAuthed}
        value={value}
        onChange={onChange}
        workspaceSlug={workspaceSlug}
        organizationSlug={organizationSlug}
        extraToolbarItems={extraToolbarItems}
      >
        <Breadcrumb>
          <BreadcrumbList>
            <BreadcrumbItem className="hidden md:block">
              <Link search={(prev) => ({ ...prev, activeElement: undefined })}>
                <BreadcrumbLink asChild>
                  <span>Workspace</span>
                </BreadcrumbLink>
              </Link>
            </BreadcrumbItem>
            <BreadcrumbSeparator className="hidden md:block" />
            <BreadcrumbItem>
              <BreadcrumbPage>Schema</BreadcrumbPage>
            </BreadcrumbItem>
          </BreadcrumbList>
        </Breadcrumb>
      </PreviewToolbarContainer>
      <EditorPreviewErrorBoundary
        value={value}
        onChange={onChange}
        organizationSlug={organizationSlug}
        workspaceRole={workspaceRole}
      >
        <LayoutRightDrawer
          placeholder={
            <LayoutPlaceholderSidebar
              value={value}
              onChange={onChange}
              activeElement={activeElement}
              componentsObject={componentsObject}
              workspaceRole={workspaceRole}
            />
          }
          sidebar={
            activeDto && (
              <LayoutSidebar>
                <LayoutSidebarHeader
                  showControls
                  onClose={() => setActiveDto(undefined)}
                >
                  <TypeBadge
                    showIcon
                    schema={componetSchema.schema}
                    className="px-5 py-5 col-span-2"
                  />
                </LayoutSidebarHeader>
                <DescriptionCard title={componetSchema.name}>
                  <EditorItemDescription
                    item={activeDto.dtoSchema}
                    onAddDescriptionClick={undefined}
                    workspaceRole={workspaceRole}
                  />
                </DescriptionCard>
                <EditorCard>
                  <SchemaQuickEditor
                    value={value}
                    onChange={onChange}
                    workspaceRole={workspaceRole}
                    title={`${activeDto.name} schema`}
                    className="grow rounded-md"
                    path={`components.schemas.${activeDto.name}`}
                    allowTopLevelReferences={false}
                  />
                </EditorCard>
              </LayoutSidebar>
            )
          }
        >
          <div className="flex flex-col gap-10 grow pt-4 pb-10">
            <div>
              <div className="flex justify-between px-4 pb-4">
                <PreviewHeading preHeading="Schema">
                  {componetSchema.name}
                </PreviewHeading>
                {isEditor && (
                  <BtnGroup>
                    <EditButton
                      onClick={() => handleEditClick(componetSchema)}
                    />
                    <DeleteButton
                      onClick={() => handleRemoveClick(componetSchema)}
                    />
                  </BtnGroup>
                )}
              </div>
              <div className="grid gap-2 px-4">
                <TypeBadge
                  showIcon
                  className="justify-self-start px-6 py-5"
                  schema={componetSchema.schema}
                />
                <SecondaryCard className="basis-full">
                  <SecondaryCardHeader>
                    <H5 className="text-muted-foreground">Description</H5>
                    {isEditor && (
                      <EditButton
                        onClick={() => handleEditClick(componetSchema)}
                      />
                    )}
                  </SecondaryCardHeader>
                  <SecondaryCardContent>
                    <EditorItemDescription
                      item={componetSchema.schema}
                      onAddDescriptionClick={() =>
                        handleEditClick(componetSchema)
                      }
                      workspaceRole={workspaceRole}
                    />
                  </SecondaryCardContent>
                </SecondaryCard>
                <SecondaryCard className="basis-full">
                  <SecondaryCardHeader>
                    <H5 className="text-muted-foreground">Schema</H5>
                  </SecondaryCardHeader>
                  <SecondaryCardContent>
                    <SchemaQuickEditor
                      value={value}
                      onChange={onChange}
                      title={`${componetSchema.name} schema`}
                      className="min-h-[200px]"
                      path={`components.schemas.${componetSchema.name}`}
                      allowTopLevelReferences={false}
                      workspaceRole={workspaceRole}
                    />
                  </SecondaryCardContent>
                </SecondaryCard>
              </div>
            </div>

            <div>
              <HideEmptyListWhenNonEditor
                workspaceRole={workspaceRole}
                list={dtoSchemaList}
              >
                <div className="grow flex flex-col gap-4 items-stretch">
                  <DocumentationCard className="">
                    <DocumentationCardHeader
                      btnGroup={
                        isEditor && (
                          <BtnGroup>
                            <AddButton onClick={handleAdd} />
                          </BtnGroup>
                        )
                      }
                    >
                      <DocumentationCardTitle>
                        Data transfer objects
                      </DocumentationCardTitle>
                    </DocumentationCardHeader>
                    <DocumentationCardContent>
                      {dtoSchemaList.map((dtoSchemaWithInfo) => {
                        return (
                          <DtoSchemaItem
                            value={value}
                            onChange={onChange}
                            key={dtoSchemaWithInfo.name}
                            dtoSchemaWithInfo={dtoSchemaWithInfo}
                            activeDto={activeDto}
                            setActiveDto={setActiveDto}
                            workspaceRole={workspaceRole}
                          />
                        );
                      })}
                      <DocumentationCardButtonGroup className="mt-2">
                        {isEditor && dtoSchemaList.length === 0 && (
                          <AddLabelButton onClick={handleAdd} />
                        )}
                      </DocumentationCardButtonGroup>
                    </DocumentationCardContent>
                  </DocumentationCard>
                </div>
              </HideEmptyListWhenNonEditor>
            </div>
          </div>
        </LayoutRightDrawer>
      </EditorPreviewErrorBoundary>
    </div>
  );
}

function DtoSchemaItem({
  dtoSchemaWithInfo,
  value,
  onChange,
  activeDto,
  setActiveDto,
  workspaceRole,
}: {
  dtoSchemaWithInfo: DtoSchemaWithInfo;
  activeDto: DtoSchemaWithInfo | undefined;
  setActiveDto: Dispatch<SetStateAction<DtoSchemaWithInfo | undefined>>;
  workspaceRole: WorkspaceRole;
} & EditorInputProps) {
  const { remove } = useSchemaDtos({ value, onChange });
  const [, actionBarDispatch] = useActionBarContext();

  const handleDeleteClick = (requestBodyName: string) => {
    setActiveDto(undefined);
    remove(requestBodyName);
  };
  const handleSetActive = () => {
    setActiveDto(dtoSchemaWithInfo);
  };

  const handleEditClick = () => {
    // IMPORTANT If we do not reset activeDto before the modeal opens
    // the editor can crash. If name of dto is updated it gets out-of-sync
    // with activeDto state.
    setActiveDto(undefined);
    actionBarDispatch({
      type: "SET_PAGE",
      payload: {
        name: "dto-edit",
        context: {
          dtoName: dtoSchemaWithInfo.name,
        },
      },
    });
  };

  const isEditor = canEdit(workspaceRole);
  return (
    <DocumentationCardItem
      isSelected={activeDto?.name === dtoSchemaWithInfo.name}
      key={dtoSchemaWithInfo.name}
      onClick={handleSetActive}
    >
      <DocumentationCardItemHeader>
        <DocumentationCardItemTitle>
          {dtoSchemaWithInfo.name}
        </DocumentationCardItemTitle>
        {isEditor && (
          <DocumentationCardItemButtonGroup>
            <EditButton
              onClick={(e) => {
                e.stopPropagation();
                handleEditClick();
              }}
            />
            <DeleteButton
              onClick={(e) => {
                e.stopPropagation();
                handleDeleteClick(dtoSchemaWithInfo.name);
              }}
            />
          </DocumentationCardItemButtonGroup>
        )}
      </DocumentationCardItemHeader>
    </DocumentationCardItem>
  );
}
