import { useActionBarContext } from "@/components/contexts/action-bar-context";
import {
  DocumentationCard,
  DocumentationCardButtonGroup,
  DocumentationCardContent,
  DocumentationCardDescription,
  DocumentationCardHeader,
  DocumentationCardItem,
  DocumentationCardItemContent,
  DocumentationCardItemHeader,
  DocumentationCardItemTitle,
  DocumentationCardTitle,
} from "@/components/documentation-card";
import {
  EditorItemDescription,
  HideEmptyListWhenNonEditor,
} from "@/components/module-api-editor";
import {
  EditorInputProps,
  WorkspaceRole,
} from "@/components/module-api-editor/types";
import {
  AddButton,
  AddLabelButton,
  BtnGroup,
  DeleteButton,
  EditButton,
} from "@/components/module-visual-editor/shared-components";
import { useServers } from "@/hooks/use-servers";
import { OASServerWithInfo } from "@/lib/editor-mutations/oas-servers";
import { canEdit, toastError } from "@/lib/utils";
import { useMemo } from "react";
import { toast } from "sonner";

export function GeneralInformationServer({
  value,
  onChange,
  workspaceRole,
}: { workspaceRole: WorkspaceRole } & EditorInputProps) {
  const { findServers, removeServer } = useServers({ value, onChange });
  const [, actionBarDispatch] = useActionBarContext();

  const serverList = useMemo(() => findServers(), [findServers]);
  const handleAdd = () => {
    actionBarDispatch({
      type: "SET_PAGE",
      payload: {
        name: "add-server",
      },
    });
  };

  const handleEdit = (serverWithInfo: OASServerWithInfo) => {
    actionBarDispatch({
      type: "SET_PAGE",
      payload: {
        name: "edit-server",
        context: {
          serverWithInfo,
        },
      },
    });
  };

  const handleRemove = (idx: number) => {
    try {
      removeServer(idx);
      toast.success("Server removed");
    } catch (err) {
      toastError(err);
    }
  };

  const isEditor = canEdit(workspaceRole);
  return (
    <HideEmptyListWhenNonEditor workspaceRole={workspaceRole} list={serverList}>
      <DocumentationCard>
        <DocumentationCardHeader
          btnGroup={
            isEditor && (
              <BtnGroup>
                <AddButton onClick={handleAdd} />
              </BtnGroup>
            )
          }
        >
          <DocumentationCardTitle className="text-sm text-muted-foreground font-normal">
            Servers
          </DocumentationCardTitle>
        </DocumentationCardHeader>
        <DocumentationCardContent>
          {serverList.map((serverWithInfo) => {
            const server = serverWithInfo.server;
            const idx = serverWithInfo.idx;
            return (
              <DocumentationCardItem
                key={`server-${serverWithInfo.server.url}`}
                className="px-0"
              >
                <DocumentationCardItemHeader>
                  <div className="flex gap-2">
                    <DocumentationCardItemTitle>
                      {server.url}
                    </DocumentationCardItemTitle>
                  </div>
                  {isEditor && (
                    <BtnGroup>
                      <EditButton
                        onClick={(evt) => {
                          evt.stopPropagation();
                          handleEdit(serverWithInfo);
                        }}
                      >
                        Edit response
                      </EditButton>
                      <DeleteButton
                        onClick={(evt) => {
                          evt.stopPropagation();
                          handleRemove(idx);
                        }}
                      >
                        Delete response
                      </DeleteButton>
                    </BtnGroup>
                  )}
                </DocumentationCardItemHeader>
                <DocumentationCardItemContent>
                  <DocumentationCardDescription>
                    <EditorItemDescription
                      item={server}
                      workspaceRole={workspaceRole}
                      onAddDescriptionClick={() => handleEdit(serverWithInfo)}
                    />
                  </DocumentationCardDescription>
                </DocumentationCardItemContent>
              </DocumentationCardItem>
            );
          })}
          {isEditor && serverList.length === 0 && (
            <DocumentationCardButtonGroup className="mt-2">
              <AddLabelButton onClick={handleAdd} />
            </DocumentationCardButtonGroup>
          )}
        </DocumentationCardContent>
      </DocumentationCard>
    </HideEmptyListWhenNonEditor>
  );
}
