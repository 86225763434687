"use client";

import React, { useState, KeyboardEvent, forwardRef } from "react";
import { X } from "lucide-react";
import { useController, Control } from "react-hook-form";
import { Badge } from "@/components/ui/badge";
import { Button } from "@/components/ui/button";
import { Input } from "@/components/ui/input";

interface EnumInputProps {
  name: string;
  control: Control<any>;
  placeholder?: string;
  validateValue: (v: string) => unknown;
}

export const MultiBadgeInput = forwardRef<HTMLInputElement, EnumInputProps>(
  (
    {
      name,
      control,
      validateValue,
      placeholder = "Type and press enter to submit...",
    },
    _ref
  ) => {
    const { field } = useController({
      name,
      control,
      defaultValue: [],
    });
    const value = field.value as string[];
    const [inputValue, setInputValue] = useState("");

    const addEnumValue = (newValue: string) => {
      if (newValue && validateValue(newValue) && !value.includes(newValue)) {
        field.onChange([...value, newValue]);
        setInputValue("");
      }
    };

    const removeEnumValue = (indexToRemove: number) => {
      field.onChange(
        value.filter((_: string, index: number) => index !== indexToRemove)
      );
    };

    const handleKeyDown = (e: KeyboardEvent<HTMLInputElement>) => {
      if (e.key === "Enter") {
        e.preventDefault();
        addEnumValue(inputValue.trim());
      }
    };

    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
      setInputValue(e.target.value);
    };

    const handleInputBlur = () => {
      if (inputValue.trim()) {
        addEnumValue(inputValue.trim());
      }
    };

    return (
      <div
        className=" border border-input rounded-md focus-within:ring-1 focus-within:ring-ring focus-within:ring-offset-2"
        ref={_ref}
      >
        <div className="flex flex-wrap items-center gap-2 p-2 max-h-32 overflow-y-auto">
          {value.map((item: string, index: number) => (
            <Badge key={index} variant="secondary" className="h-7 px-2 text-sm">
              {item}
              <Button
                type="button"
                variant="ghost"
                size="sm"
                className="h-4 w-4 p-0 ml-2"
                onClick={() => removeEnumValue(index)}
              >
                <X className="h-3 w-3" />
              </Button>
            </Badge>
          ))}
        </div>
        <Input
          type="text"
          value={inputValue}
          onChange={handleInputChange}
          onKeyDown={handleKeyDown}
          onBlur={handleInputBlur}
          className="flex-grow border-none shadow-none focus-visible:ring-0 focus-visible:ring-offset-0 h-9"
          placeholder={placeholder}
        />
      </div>
    );
  }
);
