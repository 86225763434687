import { useNavigate } from "@tanstack/react-router";
import { useCallback } from "react";

export function useProNavigation({
  organizationSlug,
}: {
  organizationSlug: string | undefined;
}) {
  const navigate = useNavigate();

  const navigateOnProButton = useCallback(async () => {
    if (organizationSlug) {
      await navigate({
        to: "/dash/$organizationSlug/pricing",
        params: { organizationSlug },
      });
    } else {
      await navigate({
        to: "/login",
      });
    }
  }, [organizationSlug, navigate]);

  return navigateOnProButton;
}
