import { useActionBarContext } from "@/components/contexts/action-bar-context";
import { useAPIEditorTools } from "@/components/contexts/api-editor-context-hooks";
import { H5 } from "@/components/headings";
import { ScrollableLayoutContainer } from "@/components/layout-right-drawer";
import {
  EditorItemDescription,
  PreviewHeading,
  PreviewToolbarContainer,
} from "@/components/module-api-editor";
import { EditorPreviewErrorBoundary } from "@/components/module-api-editor/editor-preview-error-boundary";
import { GeneralInformationSecuritySchemes } from "@/components/module-api-editor/general-information-security-schemes";
import { GeneralInformationServer } from "@/components/module-api-editor/general-information-servers";
import {
  EditorInputProps,
  WorkspaceRole,
} from "@/components/module-api-editor/types";
import {
  BtnGroup,
  EditButton,
} from "@/components/module-visual-editor/shared-components";
import {
  SecondaryCard,
  SecondaryCardContent,
  SecondaryCardHeader,
} from "@/components/secondary-card";
import {
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  BreadcrumbList,
  BreadcrumbPage,
  BreadcrumbSeparator,
} from "@/components/ui/breadcrumb";
import { canEdit } from "@/lib/utils";
import { Link } from "@tanstack/react-router";
import { ReactNode } from "react";

export function EditorPreviewGeneralInformation({
  value,
  onChange,
  organizationSlug,
  workspaceSlug,
  isAuthed,
  workspaceRole,
  extraToolbarItems,
}: {
  organizationSlug?: string;
  workspaceSlug?: string;
  isAuthed: boolean;
  workspaceRole: WorkspaceRole;
  extraToolbarItems: ReactNode;
} & EditorInputProps) {
  const { oas } = useAPIEditorTools({ value, onChange });
  const [, actionBarDispatch] = useActionBarContext();
  const onEditClick = () => {
    actionBarDispatch({
      type: "SET_PAGE",
      payload: { name: "edit-oas-metadata" },
    });
  };

  const isEditor = canEdit(workspaceRole);
  return (
    <div>
      <PreviewToolbarContainer
        workspaceRole={workspaceRole}
        value={value}
        onChange={onChange}
        isAuthed={isAuthed}
        organizationSlug={organizationSlug}
        workspaceSlug={workspaceSlug}
        extraToolbarItems={extraToolbarItems}
      >
        <Breadcrumb>
          <BreadcrumbList>
            <BreadcrumbItem className="hidden md:block">
              <Link search={(prev) => ({ ...prev, activeElement: undefined })}>
                <BreadcrumbLink asChild>
                  <span>Workspace</span>
                </BreadcrumbLink>
              </Link>
            </BreadcrumbItem>
            <BreadcrumbSeparator className="hidden md:block" />
            <BreadcrumbItem>
              <BreadcrumbPage>General information</BreadcrumbPage>
            </BreadcrumbItem>
          </BreadcrumbList>
        </Breadcrumb>
      </PreviewToolbarContainer>
      <EditorPreviewErrorBoundary
        value={value}
        onChange={onChange}
        organizationSlug={organizationSlug}
        workspaceRole={workspaceRole}
      >
        <ScrollableLayoutContainer>
          <div className="flex justify-between px-4 mb-4 mt-2">
            <PreviewHeading>{oas?.info.title || "No title yet"}</PreviewHeading>
            {isEditor && (
              <BtnGroup>
                <EditButton onClick={onEditClick} />
              </BtnGroup>
            )}
          </div>
          <div className="grid grid-cols-1 gap-10">
            <SecondaryCard className="mx-4">
              <SecondaryCardHeader>
                <H5 className="text-muted-foreground">Description</H5>
                {isEditor && <EditButton onClick={onEditClick} />}
              </SecondaryCardHeader>
              <SecondaryCardContent>
                <EditorItemDescription
                  item={oas.info}
                  onAddDescriptionClick={onEditClick}
                  workspaceRole={workspaceRole}
                />
              </SecondaryCardContent>
            </SecondaryCard>
            <GeneralInformationServer
              value={value}
              onChange={onChange}
              workspaceRole={workspaceRole}
            />
            <GeneralInformationSecuritySchemes
              value={value}
              onChange={onChange}
              workspaceRole={workspaceRole}
            />
          </div>
        </ScrollableLayoutContainer>
      </EditorPreviewErrorBoundary>
    </div>
  );
}
