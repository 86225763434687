import { Button } from "@/components/ui/button";
import {
  Card,
  CardContent,
  CardDescription,
  CardFooter,
  CardHeader,
  CardTitle,
} from "@/components/ui/card";
import {
  DashboardHeader,
  HeaderBackButton,
} from "@/components/dashboard/dashboard-header";
import { PreviewHeading } from "@/components/module-api-editor";
import { companyInfo } from "@/lib/const";
import { createFileRoute, Link } from "@tanstack/react-router";
import { ArrowRight } from "lucide-react";

const plans = [
  {
    name: "Standard",
    description: "For freelancers up to scale-ups.",
    price: "$9",
    interval: "per month per seat",
    features: [
      "Organization members",
      "Unlimited workspaces",
      "Sync files to multiple repos",
      "AI tools",
      "OpenAPI 3.0 support",
      "Unlimited Github Repositories",
      "Unlimited drafts",
    ],
  },
  {
    name: "Enterprise",
    description: "Custom solutions for large organizations",
    price: "Custom",
    interval: "pricing",
    features: [
      "SSO",
      "Unlimited workspaces",
      "Sync files to multiple repos",
      "AI tools",
      "OpenAPI 3.0 support",
      "Unlimited Github Repositories",
      "Unlimited drafts",
    ],
  },
];

export const Route = createFileRoute("/dash/$organizationSlug/pricing")({
  component: OrganizationPricing,
});

function OrganizationPricing() {
  const { organizationSlug } = Route.useParams();
  return (
    <div className="max-w-screen-xl mx-auto">
      <DashboardHeader
        organizationSlug={organizationSlug}
        showUpgradeButton={false}
        leftItems={
          <Link to="/dash/$organizationSlug/home" params={{ organizationSlug }}>
            <HeaderBackButton>Dashboard</HeaderBackButton>
          </Link>
        }
      />
      <div className="sm:py-12">
        <div className="mx-auto max-w-7xl px-4 lg:px-1">
          <PreviewHeading preHeading="Pricing">
            Choose the right plan for you
          </PreviewHeading>
          <div className="pt-6 grid grid-cols-1 gap-8 sm:grid-cols-2 lg:grid-cols-3">
            {plans.map((plan) => (
              <Card key={plan.name} className="flex flex-col justify-between">
                <CardHeader>
                  <CardTitle>{plan.name}</CardTitle>
                  <CardDescription>{plan.description}</CardDescription>
                </CardHeader>
                <CardContent>
                  <div className="mt-6 flex items-baseline gap-x-2">
                    <span className="text-5xl font-bold tracking-tight text-primary">
                      {plan.price}
                    </span>
                    <span className="text-sm font-semibold leading-6 tracking-wide text-muted-foreground">
                      {plan.interval}
                    </span>
                  </div>
                  <ul
                    role="list"
                    className="mt-8 space-y-3 text-sm leading-6 text-muted-foreground"
                  >
                    {plan.features.map((feature) => (
                      <li key={feature} className="flex gap-x-3">
                        <ArrowRight
                          className="h-6 w-5 flex-none text-primary"
                          aria-hidden="true"
                        />
                        {feature}
                      </li>
                    ))}
                  </ul>
                </CardContent>
                <CardFooter>
                  <a href={companyInfo.scheduleCall} target="_blank">
                    <Button
                      className="w-full"
                      variant={
                        plan.name === "Enterprise" ? "outline" : "default"
                      }
                    >
                      {plan.name === "Enterprise" ? "Talk to us" : "Talk to us"}
                    </Button>
                  </a>
                </CardFooter>
              </Card>
            ))}
            <Card className="flex flex-col justify-between col-span-full bg-secondary">
              <CardHeader>
                <CardTitle>API Process Consulting</CardTitle>
                <CardDescription>
                  Explore how to leverage API-first principles to develop faster
                  and more securely.
                </CardDescription>
              </CardHeader>
              <CardContent>
                <div className="mt-6 flex items-baseline gap-x-2">
                  <span className="text-5xl font-bold tracking-tight text-primary">
                    Custom
                  </span>
                  <span className="text-sm font-semibold leading-6 tracking-wide text-muted-foreground">
                    pricing
                  </span>
                </div>
                <ul
                  role="list"
                  className="mt-8 space-y-3 text-sm leading-6 text-muted-foreground"
                >
                  <li className="flex gap-x-3">
                    <ArrowRight
                      className="h-6 w-5 flex-none text-primary"
                      aria-hidden="true"
                    />
                    Backend code generation for your framework of choice
                  </li>
                  <li className="flex gap-x-3">
                    <ArrowRight
                      className="h-6 w-5 flex-none text-primary"
                      aria-hidden="true"
                    />
                    Frontend code generation for your framework of choice
                  </li>
                  <li className="flex gap-x-3">
                    <ArrowRight
                      className="h-6 w-5 flex-none text-primary"
                      aria-hidden="true"
                    />
                    Robust API change processes
                  </li>
                </ul>
              </CardContent>
              <CardFooter>
                <a href={companyInfo.scheduleCall} target="_blank">
                  <Button className="w-full">Talk to us</Button>
                </a>
              </CardFooter>
            </Card>
          </div>
        </div>
      </div>
    </div>
  );
}
