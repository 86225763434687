import { H1 } from "@/components/headings";
import { ScrollableLayoutContainer } from "@/components/layout-right-drawer";
import { PreviewToolbarContainer } from "@/components/module-api-editor";
import {
  EditorInputProps,
  WorkspaceRole,
} from "@/components/module-api-editor/types";
import { NormIcon } from "@/components/norm-icon";
import {
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  BreadcrumbList,
  BreadcrumbPage,
  BreadcrumbSeparator,
} from "@/components/ui/breadcrumb";
import { Button } from "@/components/ui/button";
import {
  Card,
  CardContent,
  CardDescription,
  CardFooter,
  CardHeader,
  CardTitle,
} from "@/components/ui/card";
import { ColorBadge } from "@/components/ui/color-badge";
import { useProNavigation } from "@/hooks/use-pro-navigation";
import { Link } from "@tanstack/react-router";
import { Ellipsis, File, Layers, Route } from "lucide-react";
import { ReactNode, useRef } from "react";

type ChatGridMessage = {
  content: string;
  sentAt: Date;
  sentBy: string;
};

function ChatGrid({ messages }: { messages: ChatGridMessage[] }) {
  return (
    <div className="grid grid-cols-1 gap-3 [&>div:nth-child(odd)]:bg-blue-500 [&>div:nth-child(even)]:bg-slate-700 [&>div:nth-child(even)]:justify-self-end">
      {messages.map((m) => (
        <div className="rounded-lg shadow-md py-3 px-3 w-2/3" key={m.content}>
          <p className="pb-2 font-semibold">{m.content}</p>
          <div className="flex justify-between ">
            <small>{m.sentBy}</small>
            <small>{m.sentAt.toDateString()}</small>
          </div>
        </div>
      ))}
      <div className="rounded-lg shadow-md py-3 px-3 w-2/3">
        <Ellipsis />
      </div>
    </div>
  );
}

export function EditorPreviewAITools({
  value,
  onChange,
  workspaceRole,
  workspaceSlug,
  organizationSlug,
  isAuthed,
  extraToolbarItems,
}: {
  organizationSlug?: string;
  workspaceSlug?: string;
  isAuthed: boolean;
  workspaceRole: WorkspaceRole;
  extraToolbarItems: ReactNode;
} & EditorInputProps) {
  const mountRef = useRef<HTMLDivElement>(null);
  const onProNavigation = useProNavigation({ organizationSlug });

  return (
    <div ref={mountRef}>
      <PreviewToolbarContainer
        workspaceRole={workspaceRole}
        isAuthed={isAuthed}
        value={value}
        onChange={onChange}
        workspaceSlug={workspaceSlug}
        organizationSlug={organizationSlug}
        extraToolbarItems={extraToolbarItems}
      >
        <Breadcrumb>
          <BreadcrumbList>
            <BreadcrumbItem className="hidden md:block">
              <Link search={(prev) => ({ ...prev, activeElement: undefined })}>
                <BreadcrumbLink asChild>
                  <span>Workspace</span>
                </BreadcrumbLink>
              </Link>
            </BreadcrumbItem>
            <BreadcrumbSeparator className="hidden md:block" />
            <BreadcrumbItem>
              <BreadcrumbPage>AI Tools</BreadcrumbPage>
            </BreadcrumbItem>
          </BreadcrumbList>
        </Breadcrumb>
      </PreviewToolbarContainer>
      <ScrollableLayoutContainer>
        <Card className="mx-5">
          <CardHeader>
            <CardTitle>
              <H1 className="">Save months of work by leveraging AI</H1>
            </CardTitle>
          </CardHeader>
          <CardContent className="grid grid-cols-2 gap-8">
            <Card className="border-2">
              <CardHeader>
                <CardTitle className="text-xl flex items-center gap-2">
                  <File />{" "}
                  <ColorBadge
                    color="blue"
                    variant="outline"
                    className="inline-block"
                  >
                    New
                  </ColorBadge>
                  Generate an OpenAPI file from any existing codebase with AI
                </CardTitle>
                <CardDescription>
                  Do you have code but no OpenAPI file for it? Generate a full
                  OpenAPI file from any programming language and any framework.
                </CardDescription>
              </CardHeader>
              <CardContent></CardContent>
              <CardFooter>
                <Button
                  variant="secondary"
                  size="lg"
                  className="w-full"
                  onClick={onProNavigation}
                >
                  <NormIcon name="Upgrade" className="mr-2" size="sm" />
                  Unlock in pro
                </Button>
              </CardFooter>
            </Card>
            <span />
            <Card className="border-2">
              <CardHeader>
                <CardTitle className="text-xl flex items-center gap-2">
                  <Route /> Ask quesitons to this workspace using AI
                </CardTitle>
                <CardDescription>
                  Make it easy for consumers to understand your API and generate
                  code from it - by adding AI chat. This can fully replace the
                  need for SDKs and save weeks of engineering time.
                </CardDescription>
              </CardHeader>
              <CardContent>
                <ChatGrid
                  messages={[
                    {
                      content: "What endpoint returns a list of users?",
                      sentAt: new Date(),
                      sentBy: "Florian Martens",
                    },
                    {
                      content:
                        "There are three endpoints returning lists of users ...",
                      sentAt: new Date(),
                      sentBy: "API AI",
                    },
                    {
                      content:
                        "Can you generate a Rust client and Axum server types for all three endpoints?",
                      sentAt: new Date(),
                      sentBy: "Florian",
                    },
                  ]}
                />
              </CardContent>
              <CardFooter>
                <Button
                  variant="secondary"
                  size="lg"
                  className="w-full"
                  onClick={onProNavigation}
                >
                  <NormIcon name="Upgrade" className="mr-2" size="sm" />
                  Unlock in pro
                </Button>
              </CardFooter>
            </Card>
            <Card className="border-2">
              <CardHeader>
                <CardTitle className="text-xl flex gap-2 items-center">
                  <Layers /> Edit and create APIs with AI
                </CardTitle>
                <CardDescription>
                  Turn anyone into an API expert by leveraging AI. Evolve
                  internal APIs with the help of AI while sticking to API best
                  practices.
                </CardDescription>
              </CardHeader>
              <CardContent>
                <ChatGrid
                  messages={[
                    {
                      content:
                        "Add endpoints to update and create invoice objects.",
                      sentAt: new Date(),
                      sentBy: "Florian",
                    },
                    {
                      content:
                        "Would you like your update performed via PATCH or PUT?",
                      sentAt: new Date(),
                      sentBy: "API AI",
                    },
                    {
                      content:
                        "Perform updates via PATCH and add the endpoint under V1 of this API.",
                      sentAt: new Date(),
                      sentBy: "Florian",
                    },
                  ]}
                />
              </CardContent>
              <CardFooter>
                <Button
                  variant="secondary"
                  size="lg"
                  className="w-full"
                  onClick={onProNavigation}
                >
                  <NormIcon name="Upgrade" className="mr-2" size="sm" />
                  Unlock in pro
                </Button>
              </CardFooter>
            </Card>
          </CardContent>
        </Card>
      </ScrollableLayoutContainer>
    </div>
  );
}
