import { WorkspaceRole } from "@/components/module-api-editor/types";
import { localStorageKeys } from "@/lib/const";
import { getApiBaseUrl } from "@/lib/http-utils";
import { bookStoreExample } from "@/lib/oas-examples/bookstore";
import { emptyWorkspaceExample } from "@/lib/oas-examples/empty-workspace";
import { ReactNode } from "@tanstack/react-router";
import { AxiosError } from "axios";
import { type ClassValue, clsx } from "clsx";
import truncate from "lodash/truncate";
import {
  ArrowDownUp,
  ArrowLeft,
  Check,
  ChevronDown,
  ChevronsRight,
  Clipboard,
  Code,
  Component,
  Computer,
  Container,
  Cookie,
  Copy,
  CopyCheck,
  Diff,
  Download,
  DownloadIcon,
  Ellipsis,
  ExternalLink,
  FileJson,
  FlagOffIcon,
  Github,
  Globe,
  Home,
  Image,
  Import,
  Info,
  InfoIcon,
  KeyRound,
  Link,
  Link2,
  Lock,
  LockIcon,
  LockOpen,
  LogIn,
  LogOut,
  Mail,
  MailCheck,
  MailIcon,
  Menu,
  Minus,
  Newspaper,
  Pencil,
  PenLine,
  Plus,
  Route,
  RouteOff,
  Save,
  Scan,
  ScanFace,
  Search,
  Settings,
  Share,
  Sparkle,
  Sparkles,
  Tag,
  Trash,
  TrashIcon,
  TriangleAlert,
  Undo,
  Unlink2,
  User,
  WandSparklesIcon,
  X,
  Zap,
} from "lucide-react";
import { nanoid } from "nanoid";
import { toast } from "sonner";
import { twMerge } from "tailwind-merge";

const EDIT_ROLES: WorkspaceRole[] = ["admin", "editor"];
const ADMIN_ROLES: WorkspaceRole[] = ["admin"];

export function canEdit(workspaceRole: WorkspaceRole) {
  return EDIT_ROLES.includes(workspaceRole);
}

export function canAdmin(workspaceRole: WorkspaceRole) {
  return ADMIN_ROLES.includes(workspaceRole);
}

export function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs));
}

export function typedIncludes<T extends U, U>(
  coll: ReadonlyArray<T>,
  el: U
): el is T {
  return coll.includes(el as T);
}

export function safeGet<T extends object, K extends keyof T>(
  obj: T,
  key: string | number | symbol
): T[K] | undefined {
  return key in obj ? obj[key as K] : undefined;
}

export function generatePlaceholderId() {
  return `%%%${nanoid().substring(0, 4)}`;
}

export function toastError(err: unknown) {
  console.error(err);
  if (err instanceof AxiosError && "message" in (err.response?.data || {})) {
    // eslint-disable-next-line
    toast.error(err.response?.data.message);
    return;
  }
  if (err instanceof Error) {
    toast.error(err.message);
    return;
  }
}

export function toastSuccess(msg: string) {
  toast.success(msg);
}

// Function to escape a string for use as a CSS identifier
export function escapeCssIdentifier(str: string) {
  return str.replace(/[^a-zA-Z0-9-_]/g, (match) => `\\${match}`);
}

export type PickRequired<T extends object, Keys extends keyof T> = T &
  Required<Pick<T, Keys>>;

export const DEFAULT_ICON_SIZE = 14;
export const DEFAULT_ICON_SIZE_LG = 18;
export const DEFAULT_ICON_SIZE_SM = 10;

export const NormIcons = {
  Auth: KeyRound,
  Tag: Tag,
  Url: Link2,
  Component: Component,
  OpenApi: Computer,
  Globals: Globe,
  Diff: Diff,
  Operation: ArrowDownUp,
  Expand: ChevronDown,
  Editor: Code,
  Download: DownloadIcon,
  Clone: CopyCheck,
  ExternalLink: ExternalLink,
  Copy: Copy,
  Duplicate: Copy,
  Optional: FlagOffIcon,
  Required: LockIcon,
  Close: Minus,
  Path: Route,
  Query: Search,
  Header: Container,
  Cookie: Cookie,
  Dereference: Unlink2,
  Delete: X,
  Check: Scan,
  Add: Plus,
  Remove: Trash,
  Minimize: ChevronsRight,
  Invite: MailIcon,
  Import: Import,
  Active: Zap,
  Lock: Lock,
  LockOpen: LockOpen,
  Clipboard: Clipboard,
  TrashCan: TrashIcon,
  JSON: FileJson,
  Edit: Pencil,
  Menu: Menu,
  More: Ellipsis,
  Save: Save,
  Feedback: User,
  Link: Link,
  Email: Mail,
  Reset: RouteOff,
  Undo: Undo,
  Upgrade: Sparkles,
  Export: Download,
  Error: TriangleAlert,
  Magic: WandSparklesIcon,
  Image: Image,
  About: Info,
  Done: Check,
  Recommended: Sparkle,
  QuickEdit: PenLine,
  Github: Github,
  Back: ArrowLeft,
  Share: Share,
  Dashboard: Home,
  Login: LogIn,
  Logout: LogOut,
  Signup: ScanFace,
  Info: InfoIcon,
  Blog: Newspaper,
  Subscribe: MailCheck,
  Settings: Settings,
} satisfies Record<string, ReactNode>;

export type OASExamples = keyof typeof oasExampleMap;

export const oasExampleMap = {
  bookStore: bookStoreExample,
  empty: emptyWorkspaceExample,
};

export async function wait(ms: number) {
  return new Promise((resolve) => setTimeout(resolve, ms));
}

export function truncateText(str: string, length: number) {
  return truncate(str, { length, omission: "..." });
}

export const slugify = (text: string) => {
  return text
    .toString() // Cast to string (optional)
    .normalize("NFKD") // The normalize() using NFKD method returns the Unicode Normalization Form of a given string.
    .toLowerCase() // Convert the string to lowercase letters
    .trim() // Remove whitespace from both sides of a string (optional)
    .replace(/\s+/g, "-") // Replace spaces with -
    .replace(/[^\w-]+/g, "") // Remove all non-word chars
    .replace(/_/g, "-") // Replace _ with -
    .replace(/--+/g, "-") // Replace multiple - with single -
    .replace(/-$/g, ""); // Remove trailing -
};

// Use this when creating model names etc
export function modelfyStr(input: string): string {
  return input.replace(/[^a-zA-Z0-9]/g, "");
}

export const getIsAuthed = () => {
  const tokens = localStorage.getItem(localStorageKeys.authTokens);
  return Boolean(tokens);
};

export async function setClipboard(text: string) {
  const type = "text/plain";
  const blob = new Blob([text], { type });
  const data = [new ClipboardItem({ [type]: blob })];
  await navigator.clipboard.write(data);
  return;
}

export function getUserCallName(username: string) {
  const first = username.split(" ")[0];
  if (first.length <= 10) return first;
  return "Friend";
}

export function isNanoid(id: string): boolean {
  // Default nanoid uses 21 characters
  if (id.length !== 21) return false;

  // nanoid uses A-Za-z0-9_- (URL-safe characters)
  const validChars = /^[A-Za-z0-9_-]+$/;
  return validChars.test(id);
}

export function buildWorkspaceUrl({
  workspaceSlug,
  organizationSlug,
}: {
  workspaceSlug: string;
  organizationSlug: string;
}) {
  const appUrl = getApiBaseUrl();

  return `${appUrl}/${organizationSlug}/${workspaceSlug}`;
}

export function buildLiveOpenApiFileUrl({
  workspaceSlug,
  organizationSlug,
  draftId,
}: {
  workspaceSlug: string;
  organizationSlug: string;
  draftId?: string;
}) {
  const apiRoute = getApiBaseUrl();
  const url = new URL(
    `${apiRoute}/v1/public/resources/oas_api_3_1/${organizationSlug}/${workspaceSlug}`
  );
  if (draftId) {
    url.search = new URLSearchParams({ draft_id: draftId }).toString();
  }
  return url.toString();
}

export function getWorkspaceUrl({
  workspaceSlug,
  organizationSlug,
}: {
  workspaceSlug: string;
  organizationSlug: string;
}) {
  const url = new URL(window.location.href);
  return `${url.protocol}//${url.host}/editor/${organizationSlug}/${workspaceSlug}`;
}

const adjectives = [
  "red",
  "blue",
  "green",
  "yellow",
  "purple",
  "orange",
  "pink",
  "brown",
  "gray",
  "black",
  "white",
  "silver",
  "gold",
  "bronze",
  "quick",
  "slow",
  "fast",
  "strong",
  "weak",
  "brave",
  "wise",
  "smart",
  "kind",
  "nice",
  "cool",
  "hot",
  "cold",
  "warm",
  "dry",
  "wet",
];

const nouns = [
  "cat",
  "dog",
  "bird",
  "fish",
  "lion",
  "tiger",
  "bear",
  "wolf",
  "fox",
  "deer",
  "elephant",
  "giraffe",
  "monkey",
  "zebra",
  "kangaroo",
  "penguin",
  "eagle",
  "hawk",
  "owl",
  "snake",
  "turtle",
  "frog",
  "rabbit",
  "squirrel",
  "mouse",
  "rat",
  "horse",
  "cow",
  "pig",
  "sheep",
];

function getRandomElement(array: string[]): string {
  return array[Math.floor(Math.random() * array.length)];
}

export function generateRandomString(length: number): string {
  const characters = "abcdefghijklmnopqrstuvwxyz0123456789";
  let result = "";
  for (let i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * characters.length));
  }
  return result;
}

export function generateRandomSlug(): string {
  const randomString = generateRandomString(6);
  return `${getRandomElement(adjectives)}-${getRandomElement(nouns)}-${randomString}`;
}

export function generateWorkspaceName(): string {
  const randomString = generateRandomString(4);
  return `${getRandomElement(adjectives)}-${getRandomElement(nouns)}-${randomString}`;
}
