import { HttpStatus } from "@/lib/helpers";
import { SupportedHTTPVerbs } from "@/lib/types";

export const templateResponseCodes = [
  "202",
  "204",
  "400",
  "401",
  "403",
  "404",
  "409",
  "422",
  "500",
] as const;
export type TemplateResponseCodes = (typeof templateResponseCodes)[number];

// export type StatusCodeDescription<Codes extends string> = {
//   code: Codes;
//   description: string;
// };

type VerbStatusCodes = Record<
  SupportedHTTPVerbs,
  {
    custom: Array<HttpStatus>;
    components: Array<TemplateResponseCodes>;
    authComponents: Array<TemplateResponseCodes>;
  }
>;

export const httpMethodResponseCodes: VerbStatusCodes = {
  get: {
    custom: ["200"],
    components: ["400", "404", "500"],
    authComponents: ["401", "403"],
  },
  post: {
    custom: ["201"],
    components: ["400", "409", "500"],
    authComponents: ["401", "403"],
  },
  put: {
    custom: ["200"],
    components: ["400", "404", "409", "500"],
    authComponents: ["401", "403"],
  },
  patch: {
    custom: ["200"],
    components: ["400", "404", "500"],
    authComponents: ["401", "403"],
  },
  delete: {
    custom: ["204"],
    components: ["400", "404", "500"],
    authComponents: ["401", "403"],
  },
  options: {
    custom: ["200"],
    components: ["500"],
    authComponents: ["401", "403"],
  },
  head: {
    custom: ["200"],
    components: ["404", "500"],
    authComponents: [],
  },
  trace: {
    custom: ["200"],
    components: ["400", "500"],
    authComponents: ["401", "403"],
  },
};
