import { useActionBarContext } from "@/components/contexts/action-bar-context";
import { useAPIEditorTools } from "@/components/contexts/api-editor-context-hooks";
import { H2, H4 } from "@/components/headings";
import { EditorInputProps } from "@/components/module-api-editor/types";
import {
  SecondaryCard,
  SecondaryCardContent,
  SecondaryCardHeader,
} from "@/components/secondary-card";
import { Badge } from "@/components/ui/badge";
import { Button } from "@/components/ui/button";
import { ColorBadge } from "@/components/ui/color-badge";
import {
  Dialog,
  DialogContentWithoutButton,
  DialogHeader,
} from "@/components/ui/dialog";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu";
import { StandardDialogProps } from "@/lib/types";
import { Bot, File, Github, PackageCheck } from "lucide-react";

export function EmptyWorkspaceDialog({
  value,
  onChange,
  isOpen,
}: EditorInputProps & { organizationSlug?: string } & StandardDialogProps) {
  const { resetToExample } = useAPIEditorTools({ value, onChange });
  const [, actionBarDispatch] = useActionBarContext();

  const handleAddOperation = () => {
    actionBarDispatch({ type: "SET_PAGE", payload: { name: "add-operation" } });
  };

  const handleImportClick = () => {
    actionBarDispatch({
      type: "SET_PAGE",
      payload: { name: "import-open-api-file" },
    });
  };

  const handleStartWithExample = async () => {
    await resetToExample("bookStore");
  };
  return (
    <Dialog open={isOpen}>
      <DialogContentWithoutButton>
        <DialogHeader className="">
          <H2 className="font-bold">Welcome to API-Fiddle</H2>
          <p className="text-sm text-muted-foreground">
            A modern editor based on OpenAPI used by 500+ organizations like:
          </p>
          <div className="grid grid-cols-4 items-center w-4/5 gap-3 pb-3 pt-1">
            <img src="/img/ticketmaster-logo.svg" />
            <img src="/img/solidgate-logo.svg" />
            <img src="/img/algolia-logo.svg" />
          </div>
          <p className="text-sm text-muted-foreground">
            Things you can do with API-Fiddle:
          </p>
        </DialogHeader>
        <div>
          <ul className="pb-4 text-sm leading-7">
            <li>
              <ColorBadge
                color="indigo"
                variant="outline"
                className="inline-flex items-center gap-1"
              >
                <PackageCheck size={14} className="text-brandSoft" />
                Jira Tickets
              </ColorBadge>{" "}
              Describe API changes in ticket systems.
            </li>
            <li>
              <ColorBadge
                color="indigo"
                variant="outline"
                className="inline-flex items-center gap-1"
              >
                <File size={14} className="text-brandSoft" />
                OpenAPI
              </ColorBadge>{" "}
              Create and edit OpenAPI files.
            </li>
            <li>
              <ColorBadge
                color="indigo"
                variant="outline"
                className="inline-flex items-center gap-1"
              >
                <Github size={14} className="text-brandSoft" />
                Github
              </ColorBadge>{" "}
              Sync OpenAPI files with Github.
            </li>
            <li>
              <ColorBadge
                color="indigo"
                variant="outline"
                className="inline-flex items-center gap-1"
              >
                <Bot size={14} className="text-brandSoft" />
                AI
              </ColorBadge>{" "}
              API-first and AI-first development.{" "}
            </li>
          </ul>

          <div className="grid gap-3 grid-cols-1">
            <p className="text-sm text-muted-foreground">Getting started</p>

            <div className="grid grid-cols-2 gap-2 auto-rows-fr">
              <SecondaryCard>
                <SecondaryCardHeader>
                  <H4 className="font-bold">
                    Generate OpenAPI from Github repository with AI
                  </H4>
                </SecondaryCardHeader>
                <SecondaryCardContent>
                  <p
                    className="text-sm hyphens-auto text-muted-foreground pb-2"
                    lang="en"
                  >
                    Create full OpenAPI documentation from any Github Repository
                    with Claude 3 Opus.
                  </p>
                  <div>
                    <Button
                      size="sm"
                      className="w-full flex gap-2"
                      variant="brand"
                      disabled
                    >
                      Get started
                      <Badge className="h-5 border border-input border-none ">
                        Pro
                      </Badge>
                    </Button>
                  </div>
                </SecondaryCardContent>
              </SecondaryCard>
              <SecondaryCard className="grid-rows-[max-content,1fr] items-stretch">
                <SecondaryCardHeader>
                  <H4 className="font-bold">Start with Bookstore example</H4>
                </SecondaryCardHeader>
                <SecondaryCardContent className="flex flex-col items-stretch">
                  <p
                    className="text-sm hyphens-auto text-muted-foreground pb-2 grow"
                    lang="en"
                  >
                    Start from a fully configured workspace to see how routes,
                    parameters, and more are defined.
                  </p>
                  <div>
                    <DropdownMenu modal={false}>
                      <DropdownMenuTrigger asChild>
                        <Button
                          size="sm"
                          className="w-full flex gap-2"
                          variant="default"
                        >
                          Create Bookstore
                        </Button>
                      </DropdownMenuTrigger>
                      <DropdownMenuContent>
                        <DropdownMenuItem onClick={handleStartWithExample}>
                          Use OpenAPI 3.1
                        </DropdownMenuItem>
                        <DropdownMenuItem disabled>
                          OpenAPI 3.0.x <Badge className="h-5 ml-2">Pro</Badge>
                        </DropdownMenuItem>
                      </DropdownMenuContent>
                    </DropdownMenu>
                  </div>
                </SecondaryCardContent>
              </SecondaryCard>
              <SecondaryCard className="grid-rows-[max-content,1fr] items-stretch">
                <SecondaryCardHeader>
                  <H4 className="font-bold">
                    Start by creating your first Operation
                  </H4>
                </SecondaryCardHeader>
                <SecondaryCardContent className="flex flex-col items-stretch">
                  <p
                    className="text-sm hyphens-auto text-muted-foreground pb-2 grow"
                    lang="en"
                  >
                    Begin by creating your first HTTP route.
                  </p>
                  <div>
                    <DropdownMenu modal={false}>
                      <DropdownMenuTrigger asChild>
                        <Button
                          size="sm"
                          className="w-full flex gap-2"
                          variant="default"
                        >
                          Create Operation
                        </Button>
                      </DropdownMenuTrigger>
                      <DropdownMenuContent>
                        <DropdownMenuItem onClick={handleAddOperation}>
                          Use OpenAPI 3.1
                        </DropdownMenuItem>
                        <DropdownMenuItem disabled>
                          OpenAPI 3.0.x <Badge className="h-5 ml-2">Pro</Badge>
                        </DropdownMenuItem>
                      </DropdownMenuContent>
                    </DropdownMenu>
                  </div>
                </SecondaryCardContent>
              </SecondaryCard>
              <SecondaryCard className="grid-rows-[max-content,1fr] items-stretch">
                <SecondaryCardHeader>
                  <H4 className="font-bold">Start by importing</H4>
                </SecondaryCardHeader>
                <SecondaryCardContent className="flex flex-col items-stretch">
                  <p
                    className="text-sm hyphens-auto text-muted-foreground pb-2 grow"
                    lang="en"
                  >
                    Import an existing OpenAPI file created with API-Fiddle.
                  </p>
                  <div>
                    <Button
                      size="sm"
                      className="w-full flex gap-2"
                      variant="default"
                      onClick={handleImportClick}
                    >
                      Import
                    </Button>
                  </div>
                </SecondaryCardContent>
              </SecondaryCard>
            </div>
          </div>
        </div>
      </DialogContentWithoutButton>
    </Dialog>
  );
}
