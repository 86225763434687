import { useAPIEditorTools } from "@/components/contexts/api-editor-context-hooks";
import { H1, H4 } from "@/components/headings";
import LayoutRightDrawer, {
  LayoutSidebar,
  LayoutSidebarContent,
} from "@/components/layout-right-drawer";
import { PreviewToolbarContainer } from "@/components/module-api-editor";
import {
  ActiveElement,
  EditorInputProps,
  WorkspaceRole,
} from "@/components/module-api-editor/types";
import { NormIcon } from "@/components/norm-icon";
import { ProBadge } from "@/components/special-badges";
import WaveLoading from "@/components/svg-animations/wave-loading";
import {
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  BreadcrumbList,
  BreadcrumbPage,
  BreadcrumbSeparator,
} from "@/components/ui/breadcrumb";
import { Button } from "@/components/ui/button";
import { Card, CardContent, CardHeader, CardTitle } from "@/components/ui/card";
import { ColorBadge } from "@/components/ui/color-badge";
import { useProNavigation } from "@/hooks/use-pro-navigation";
import { tokyoNight } from "@/lib/codemirror-theme";
import { OASDefinition } from "@/lib/types";
import { toastError } from "@/lib/utils";
import { yaml } from "@codemirror/lang-yaml";
import { Diagnostic, linter, lintGutter } from "@codemirror/lint";
import { Link } from "@tanstack/react-router";
import CodeMirror from "@uiw/react-codemirror";
import debounce from "lodash/debounce";
import { Check } from "lucide-react";
import { ReactNode, useCallback, useRef, useState } from "react";
import { parse, stringify } from "yaml";

export function EditorPreviewEditor({
  value,
  onChange,
  workspaceRole,
  workspaceSlug,
  organizationSlug,
  isAuthed,
  extraToolbarItems,
}: {
  organizationSlug?: string;
  workspaceSlug?: string;
  isAuthed: boolean;
  workspaceRole: WorkspaceRole;
  extraToolbarItems: ReactNode;
  activeElement: Extract<ActiveElement, { type: "editor" }>;
} & EditorInputProps) {
  const [saving, setSaving] = useState(false);
  const [textValue, setTextValue] = useState(stringify(value.data));
  const { setNewEditorState } = useAPIEditorTools({ value, onChange });
  const onProNavigation = useProNavigation({ organizationSlug });

  const mountRef = useRef<HTMLDivElement>(null);

  const yamlLinter = linter((view) => {
    const diagnostics: Diagnostic[] = [];
    const doc = view.state.doc.toString();

    try {
      parse(doc);
    } catch (e) {
      if (e instanceof Error) {
        diagnostics.push({
          from: 0,
          to: doc.length,
          severity: "error",
          message: e.message,
        });
      }
    }

    return diagnostics;
  });

  const handleEditorStateUpdate = useCallback(
    debounce((currentEditorValue: string) => {
      if (!mountRef.current) return;
      try {
        const parsed = parse(currentEditorValue) as OASDefinition;
        setSaving(false);
        setNewEditorState({ data: parsed, document_type: value.document_type });
      } catch (err) {
        toastError(err);
      }
    }, 1500),
    [value.document_type]
  );

  const handleChange = (v: string) => {
    setTextValue(v);
    setSaving(true);
    handleEditorStateUpdate(v);
  };

  return (
    <div ref={mountRef}>
      <PreviewToolbarContainer
        workspaceRole={workspaceRole}
        isAuthed={isAuthed}
        value={value}
        onChange={onChange}
        workspaceSlug={workspaceSlug}
        organizationSlug={organizationSlug}
        extraToolbarItems={extraToolbarItems}
      >
        <Breadcrumb>
          <BreadcrumbList>
            <BreadcrumbItem className="hidden md:block">
              <Link search={(prev) => ({ ...prev, activeElement: undefined })}>
                <BreadcrumbLink asChild>
                  <span>Workspace</span>
                </BreadcrumbLink>
              </Link>
            </BreadcrumbItem>
            <BreadcrumbSeparator className="hidden md:block" />
            <BreadcrumbItem>
              <BreadcrumbPage>Editor</BreadcrumbPage>
            </BreadcrumbItem>
          </BreadcrumbList>
        </Breadcrumb>
      </PreviewToolbarContainer>

      <LayoutRightDrawer
        placeholder={
          <LayoutSidebar className="bg-background py-0">
            <LayoutSidebarContent>
              <Card>
                <CardContent className="flex items-center justify-center pb-1 pt-2 gap-2">
                  {saving ? (
                    <div className="w-6 h-12 text-brand">
                      <WaveLoading />
                    </div>
                  ) : (
                    <div className="w-6 h-12 grid place-items-center text-brand">
                      <Check size={14} />
                    </div>
                  )}
                  <div>
                    <H4>{saving ? "Saving changes" : "Up to date"}</H4>
                  </div>
                </CardContent>
              </Card>
              <Card>
                <CardHeader>
                  <CardTitle>
                    <ProBadge
                      organizationSlug={organizationSlug}
                      className="mb-2"
                    />
                    <H1>Unlock 10+ suggestions for this workspace</H1>
                  </CardTitle>
                </CardHeader>
                <CardContent>
                  <p className="text-muted-foreground pb-3">
                    Unlock detailed suggestions for this workspace by moving to
                    a pro plan.
                  </p>
                  <div className="blur-sm">
                    <ul className="space-y-2 pb-3">
                      <li className="text-sm">
                        <ColorBadge
                          variant="outline"
                          color="red"
                          className="mr-2 h-5"
                        >
                          err
                        </ColorBadge>
                        <b>Line 23</b>: Lorem ipsum dolor sit amet, consectetur
                        adipiscing elit, sed do eiusmod.
                      </li>
                      <li className="text-sm">
                        <ColorBadge
                          variant="outline"
                          color="red"
                          className="mr-2 h-5"
                        >
                          err
                        </ColorBadge>
                        <b>Line 23</b>: Lorem ipsum dolor sit amet, consectetur
                        adipiscing elit, sed do eiusmod.
                      </li>
                      <li className="text-sm">
                        <ColorBadge
                          variant="outline"
                          color="orange"
                          className="mr-2 h-5"
                        >
                          warn
                        </ColorBadge>
                        <b>Line 23</b>: Lorem ipsum dolor sit amet, consectetur
                        adipiscing elit, sed do eiusmod.
                      </li>
                      <li className="text-sm">
                        <ColorBadge
                          variant="outline"
                          color="blue"
                          className="mr-2 h-5"
                        >
                          tip
                        </ColorBadge>
                        <b>Line 23</b>: Lorem ipsum dolor sit amet, consectetur
                        adipiscing elit, sed do eiusmod.
                      </li>
                    </ul>
                  </div>
                  <Button
                    variant="secondary"
                    size="lg"
                    className="w-full"
                    onClick={onProNavigation}
                  >
                    <NormIcon name="Upgrade" size="sm" className="mr-3" />
                    Go pro
                  </Button>
                </CardContent>
              </Card>
            </LayoutSidebarContent>
          </LayoutSidebar>
        }
        sidebar={undefined}
      >
        <CodeMirror
          value={textValue}
          onChange={handleChange}
          theme={tokyoNight}
          height="100%"
          extensions={[yaml(), yamlLinter, lintGutter()]}
        />
      </LayoutRightDrawer>
    </div>
  );
}
